import * as dates from './dates'
import events from './events'
import './style.scss'
import classNames from 'classnames'
import Link from 'gatsby-link'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useMemo, useState, useEffect } from 'react'
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from 'react-big-calendar'

const mLocalizer = momentLocalizer(moment)

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

const EventPopover = ({ event, onClose, position }) => {
  return (
    <div
      className='rbc-event-popover-wrapper'
      style={{
        top: position.y,
        left: position.x,
        transform: 'translate(-50%, -100%)',
        marginTop: '-8px',
      }}
    >
      <div className='caret-shadow'></div>
      <div className='caret'></div>
      <div className='rbc-event-popover'>
        <div className='rbc-event-popover-header'>
          <div className='rbc-event-popover-title'>
            Reservation for {moment(event.start).format('MMM D')}
            {!moment(event.start).isSame(moment(event.end), 'day') &&
              `-${moment(event.end).format('D')}`}
          </div>
        </div>

        <div className='rbc-event-popover-content'>
          <div className='rbc-event-popover-section'>
            <div className='rbc-event-popover-owner'>
              <div className='rbc-event-popover-owner-avatars'>
                {event.pets &&
                  event.pets.map((pet) => (
                    <Link
                      key={pet}
                      to={`/profile/${pet}`}
                      className='rbc-event-popover-owner-avatar'
                      data-pet-name={pet}
                    >
                      <img
                        src={`https://ui-avatars.com/api/?name=${pet}&background=random`}
                        alt={pet}
                        width={30}
                        height={30}
                      />
                    </Link>
                  ))}
              </div>
              <div className='rbc-event-popover-reservation-pets'>
                {event.pets &&
                  event.pets.map((pet) => (
                    <React.Fragment key={pet}>
                      <Link to={`/profile/${pet}`} data-pet-name={pet}>
                        {pet}
                      </Link>
                      {event.pets.indexOf(pet) < event.pets.length - 1 && ', '}
                    </React.Fragment>
                  ))}
              </div>
              <div className='rbc-event-popover-reservation-dates'>
                <span>{moment(event.start).format('ddd D')}</span>
                <span>-{moment(event.end).format('ddd D')}</span>
              </div>
            </div>
            <div className='rbc-event-popover-owner-details'>
              <div className='rbc-event-popover-owner-name'>
                👤 Victoria Pelham
              </div>
              <div className='rbc-event-popover-owner-phone'>
                📞 (555) 123-4567
              </div>
              <div className='rbc-event-popover-owner-email'>
                ✉️ john.doe@example.com
              </div>
            </div>
          </div>
        </div>

        <div className='rbc-event-popover-actions'>
          <Link to='/message/' className='rbc-event-popover-chat-btn'>
            Send message
          </Link>
        </div>
      </div>
    </div>
  )
}

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main 'About' page in Storybook
 */
export default function Basic({
  localizer = mLocalizer,
  showDemoLink = true,
  ...props
}) {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Don't close if clicking on the event itself
      if (e.target.closest('.rbc-event')) {
        return
      }

      // Check if click is outside both popups
      const isOutsideEventPopover = !e.target.closest('.rbc-event-popover')
      const isOutsideShowMore = !e.target.closest('.rbc-overlay')

      if (isOutsideEventPopover && isOutsideShowMore) {
        setSelectedEvent(null)
      }
    }

    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        // Close our custom event popover
        setSelectedEvent(null)

        // Close show more popup by triggering a click on the document body
        const showMorePopup = document.querySelector('.rbc-overlay')
        if (showMorePopup) {
          document.body.click()
        }
      }
    }

    // Add a small delay to prevent immediate closing
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleClickOutside)
      document.addEventListener('keydown', handleEscapeKey)
    }, 100)

    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [])

  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
        eventWrapper: (eventWrapperProps) => {
          const isUnavailable = eventWrapperProps.event.unavailable
          return (
            <div
              className={classNames(eventWrapperProps.event)}
              onMouseDown={(e) => {
                // Don't show popup if click is from the show more popup
                if (e.target.closest('.rbc-overlay')) {
                  return
                }

                if (!isUnavailable) {
                  e.preventDefault()
                  e.stopPropagation()
                  const rect = e.currentTarget.getBoundingClientRect()
                  const calendarRect = e.currentTarget
                    .closest('.calendar')
                    .getBoundingClientRect()
                  setPopupPosition({
                    x: rect.left - calendarRect.left + rect.width / 2,
                    y: rect.top - calendarRect.top - 8, // Add 8px offset for the caret
                  })
                  setSelectedEvent(eventWrapperProps.event)
                }
              }}
            >
              {React.cloneElement(eventWrapperProps.children, {
                children: (
                  <>
                    {isUnavailable && (
                      <div className='rbc-event-unavailable'>Unavailable</div>
                    )}
                    {!isUnavailable && (
                      <>
                        {' $' + eventWrapperProps.event.price.toFixed(0)}
                        {eventWrapperProps.event.pets &&
                          ' ' + eventWrapperProps.event.pets.join(', ')}
                      </>
                    )}
                  </>
                ),
              })}
            </div>
          )
        },
      },
      defaultDate: new Date(2015, 3, 1),
      max: dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  )

  return (
    <Fragment>
      <div className='calendar'>
        <div className='height600' {...props}>
          <Calendar
            components={components}
            defaultDate={defaultDate}
            events={events}
            localizer={localizer}
            max={max}
            showMultiDayTimes
            step={60}
            length={3}
            popup
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setSelectedEvent(null)
                // Close show more popup by triggering a click on the document body
                const showMorePopup = document.querySelector('.rbc-overlay')
                if (showMorePopup) {
                  document.body.click()
                }
              }
            }}
            views={{
              month: true,
              day: false,
              week: false,
              work_week: false,
            }}
          />
          {selectedEvent && (
            <div className='rbc-event-popover-container'>
              <EventPopover
                event={selectedEvent}
                onClose={() => setSelectedEvent(null)}
                position={popupPosition}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}
Basic.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}
