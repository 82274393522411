const now = new Date()

export default [
  /* {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  }, */
  {
    id: 1,
    title: 'Reservation',
    price: 294.0,
    pets: ['Gotti', 'Capone'],
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
    allDay: true,
  },

  {
    id: 2,
    title: 'DTS STARTS',
    price: 150.0,
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: 'DTS ENDS',
    price: 150.0,
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: 'Reservation',
    price: 82.0,
    pets: ['Farrah'],
    start: new Date(2015, 3, 9, 8, 0, 0),
    end: new Date(2015, 3, 10, 11, 30, 0),
  },
  {
    id: 5,
    title: 'Reservation',
    price: 55.0,
    pets: ['Gary'],
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0),
  },
  {
    id: 6,
    title: 'Reservation',
    price: 60.0,
    pets: ['Lady', 'Sasha'],
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 17, 19, 30, 0),
  },
  {
    id: 7,
    title: 'Unavailable',
    price: 0.0,
    start: new Date(2015, 2, 30, 19, 30, 0),
    end: new Date(2015, 2, 30, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 8,
    title: 'Unavailable',
    price: 0.0,
    start: new Date(2015, 3, 6, 19, 30, 0),
    end: new Date(2015, 3, 6, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 9,
    title: 'Unavailable',
    price: 0.0,
    start: new Date(2015, 3, 13, 19, 30, 0),
    end: new Date(2015, 3, 13, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 10,
    title: 'Unavailable',
    price: 0.0,
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 20, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 11,
    title: 'Unavailable',
    price: 0.0,
    start: new Date(2015, 3, 27, 19, 30, 0),
    end: new Date(2015, 3, 27, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 12,
    title: 'Reservation',
    price: 420.0,
    pets: ['Jericho', 'Angel', 'Charlie'],
    start: new Date(2015, 3, 21, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0),
  },
  {
    id: 13,
    title: 'Reservation',
    price: 164.0,
    pets: ['Boss', 'Dolce', 'Prada', 'Bella'],
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 48)),
  },
  {
    id: 14,
    title: 'Reservation',
    price: 65.0,
    pets: ['Cooper'],
    start: now,
    end: new Date(new Date().setHours(new Date().getHours() + 24)),
  },
  {
    id: 15,
    title: 'Reservation',
    price: 24.0,
    pets: ['Minnie'],
    start: new Date(2015, 3, 14, 15, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0),
  },
  {
    id: 16,
    title: 'Reservation',
    price: 32.0,
    pets: ['Buttercup'],
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0),
  },
  {
    id: 17,
    title: 'Reservation',
    price: 32.0,
    pets: ['Princess'],
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 17, 30, 0),
  },
  {
    id: 18,
    title: 'Reservation',
    price: 24.0,
    pets: ['Mouse'],
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 20, 30, 0),
  },
  {
    id: 19,
    title: 'Reservation',
    price: 55.0,
    pets: ['Rain'],
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
  {
    id: 20,
    title: 'Reservation',
    price: 180.0,
    pets: ['Waffles', 'Pancake'],
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
]
