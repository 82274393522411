import { useProfileData } from '../AuthProvider'
import Calendar from '../Calendar'
import Modal from '../Modal'
import Popover from '../Popover'
import SidebarNavigation from '../SidebarNavigation'
import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React, { useState } from 'react'
import 'react-dates/initialize'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover'

const PageDashboard = (props) => {
  const profile = useProfileData()
  const editPrices = useDialogState({
    baseId: 'editPrices',
    hideOnEsc: true,
  })
  const popoverStatus = usePopoverState({ baseId: 'popoverStatus' })
  const popoverPayment = usePopoverState({ baseId: 'popoverPayment' })

  // State to manage whether the toggle is disabled
  const [isDisabled, setIsDisabled] = useState(true)

  function PriceModal({ editPrices }) {
    const form = useFormState({})

    return (
      <>
        <DialogDisclosure className={style.button} {...editPrices}>
          <span className={style.desktopCopy}>Edit prices</span>
          <span className={style.mobileCopy}>Prices</span>
        </DialogDisclosure>
        <Modal {...editPrices}>
          <div className={style.modal}>
            <h2>Prices</h2>
            <p>
              You have not created any prices. Only days that have a price can
              be reserved. <Link to='/how-prices-work'>Learn more</Link>
            </p>
            <Form className={style.priceForm}>
              <div className={style.inputGroup}>
                <div className={style.priceInput}>
                  <label htmlFor='price'>Price</label>
                  <input name='price' type='number'></input>
                </div>
                <div className={style.inputGroup}>
                  <label>Choose days for this price</label>
                  <div className={style.checkboxContainer}>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='monday'
                        checked
                      />{' '}
                      <div className={style.checkboxLabel}>Monday</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='tuesday'
                      />{' '}
                      <div className={style.checkboxLabel}>Tuesday</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='wednesday'
                      />{' '}
                      <div className={style.checkboxLabel}>Wednesday</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='thursday'
                      />{' '}
                      <div className={style.checkboxLabel}>Thursday</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='friday'
                      />{' '}
                      <div className={style.checkboxLabel}>Friday</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='saturday'
                      />{' '}
                      <div className={style.checkboxLabel}>Saturday</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='price'
                        value='sunday'
                      />{' '}
                      <div className={style.checkboxLabel}>Sunday</div>
                    </label>
                  </div>
                </div>
                <div className={style.buttonContainer}>
                  <FormSubmitButton className={style.buttonSubmit}>
                    Save prices
                  </FormSubmitButton>
                  <button className={style.buttonCancel}>Close</button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </>
    )
  }

  function AvailabilityModal() {
    const editAvailability = useDialogState({ baseId: 'editAvailability' })
    const form = useFormState({})

    return (
      <>
        <DialogDisclosure className={style.button} {...editAvailability}>
          <span className={style.desktopCopy}>Change availability</span>
          <span className={style.mobileCopy}>Availability</span>
        </DialogDisclosure>
        <Modal {...editAvailability}>
          <div className={style.modal}>
            <h2>Unavailable days</h2>
            <p>
              Want to make days unavailable? Choose any days you don't want
              booked, and they'll become unavailable on your calendar.
            </p>
            <Form className={style.dayForm} {...form}>
              <div className={style.inputGroup}>
                <div className={style.checkboxContainer}>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='monday'
                      checked
                    />{' '}
                    <div className={style.checkboxLabel}>Monday</div>
                  </label>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='tuesday'
                    />{' '}
                    <div className={style.checkboxLabel}>Tuesday</div>
                  </label>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='wednesday'
                    />{' '}
                    <div className={style.checkboxLabel}>Wednesday</div>
                  </label>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='thursday'
                    />{' '}
                    <div className={style.checkboxLabel}>Thursday</div>
                  </label>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='friday'
                    />{' '}
                    <div className={style.checkboxLabel}>Friday</div>
                  </label>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='saturday'
                    />{' '}
                    <div className={style.checkboxLabel}>Saturday</div>
                  </label>
                  <label className={style.checkboxGroup}>
                    <FormCheckbox
                      {...form}
                      className={style.checkbox}
                      name='unavailable'
                      value='sunday'
                    />{' '}
                    <div className={style.checkboxLabel}>Sunday</div>
                  </label>
                </div>
              </div>
              <div className={style.buttonContainer}>
                <FormSubmitButton className={style.buttonSubmit}>
                  Save days
                </FormSubmitButton>
                <button className={style.buttonCancel}>Close</button>
              </div>
            </Form>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <Template fullWidth>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className={style.pageWrapper}>
        <SidebarNavigation />
        <div className={style.dashboardContent}>
          <div className={style.headerContainer}>
            <h1>Dashboard</h1>
            <div className={style.profileDetails}>
              {/* Conditionally render PopoverDisclosure and Popover if toggle is disabled */}
              {isDisabled && (
                <PopoverDisclosure
                  className={style.popoverStatus}
                  {...popoverStatus}
                >
                  <p>Status</p>
                  <label className={style.toggle}>
                    <input type='checkbox' disabled={isDisabled} />
                    <span className={`${style.slider} ${style.round}`}></span>
                  </label>
                </PopoverDisclosure>
              )}

              {isDisabled && (
                <Popover
                  {...popoverStatus}
                  aria-label='Status Info'
                  tabIndex={0}
                >
                  <div className={style.popoverContent}>
                    <p>
                      Your profile is currently off and won't appear in search
                      results or accept new reservations
                    </p>
                    <p>
                      <DialogDisclosure className={style.link} {...editPrices}>
                        Add prices
                      </DialogDisclosure>{' '}
                      and{' '}
                      <Link className={style.link} to='/reliable-pet-care'>
                        setup your profile
                      </Link>
                      , then try again
                    </p>
                  </div>
                </Popover>
              )}
            </div>
          </div>
          <div className={style.notification}>
            <p>
              Before you can go live, you need to{' '}
              <strong>
                <DialogDisclosure
                  className={style.editPricesLink}
                  {...editPrices}
                >
                  create prices
                </DialogDisclosure>
              </strong>{' '}
              and{' '}
              <Link className={style.profileLink} to={'/reliable-pet-care'}>
                setup your profile
              </Link>
            </p>
          </div>
          <div className={style.calendarSection}>
            <Calendar />
          </div>
          <div className={style.reservationSection}>
            <div className={style.titleContainer}>
              <h3>
                <span className={style.desktopCopy}>Reservation requests</span>
                <span className={style.mobileCopy}>Requests</span>
              </h3>
              <div className={style.buttonContainer}>
                <PriceModal editPrices={editPrices} />
                <AvailabilityModal />
              </div>
            </div>
            {/* <div className={style.reservationRequestTable}>
              <div className={style.column}>
                <div>
                  <span>
                    <Link to='/message/?c=doggoscom-inc&m=ewogICJzdGFydERhdGUiOiAxNzIyNTUxNjIyODg0LAogICJlbmREYXRlIjogMTcyMjU1MTYyMjk4Mgp9'>
                      Jacqueline from Doggos.com
                    </Link>
                  </span>
                </div>
              </div>
              <div className={style.column}>
                <div>
                  <span>$340</span> <span>1 dog</span>
                </div>
              </div>
              <div className={style.column}>
                <div>
                  <span>Aug 24 - Sep 1, 2024</span>
                </div>
              </div>
              <div className={style.column}>
                <div>
                  <span>Approve / Archive</span>
                </div>
              </div>
            </div> */}
            <div className={style.reservationRequestTable}>
              New requests will appear here
            </div>
          </div>
          <div className={style.paymentSection}>
            <div className={style.titleContainer}>
              <h3>Payments</h3>
              <div className={style.buttonContainer}>
                <PopoverDisclosure
                  className={style.buttonPopoverContainer}
                  {...popoverPayment}
                >
                  <div className={style.button}>
                    <span className={style.desktopCopy}>Connect bank</span>
                    <span className={style.mobileCopy}>Bank</span>
                  </div>
                  <div className={style.button}>
                    <span className={style.desktopCopy}>Order history</span>
                    <span className={style.mobileCopy}>Orders</span>
                  </div>
                </PopoverDisclosure>
              </div>
            </div>
            <Popover {...popoverPayment} aria-label='Payment Info' tabIndex={0}>
              <div className={style.popoverContent}>
                <span>
                  After your first completed reservation, you'll be able to
                  connect your bank and view your order history.
                </span>
              </div>
            </Popover>
            <div className={style.paymentTable}>
              <div className={style.column}>Payments will appear here</div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default PageDashboard
